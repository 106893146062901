import type { PluginListenerHandle } from "@capacitor/core";
import { Capacitor } from "@capacitor/core";
import { ImpactStyle } from "@capacitor/haptics";
import { Keyboard } from "@capacitor/keyboard";
import * as React from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { hapticsImpact } from "~/config/capacitor/haptics/hook";
import { useUser } from "~/config/user/UserProvider";
import { Box } from "~/design-system/Box";
import { Drawer, DrawerContent, DrawerTrigger } from "~/design-system/Drawer";
import { useBreakpoint } from "~/design-system/hooks";
import { createComponentHook } from "~/types";
import { NavigationDrawerHeader } from "./components/NavigationDrawerHeader/NavigationDrawerHeader";
import { ProjectListMenu } from "./components/ProjectListMenu/ProjectListMenu";
import { SettingsDrawerButton } from "./components/SettingsDrawer/components/SettingsDrawerButton";
import { SettingsDrawer } from "./components/SettingsDrawer/SettingsDrawer";
import type {
  NavigationDrawer_OrgQuery,
  NavigationDrawer_UserQuery,
} from "./NavigationDrawer.graphql";
import {
  useNavigationDrawer_OrgQuery,
  useNavigationDrawer_UserQuery,
} from "./NavigationDrawer.graphql";

type UserInfos = NonNullable<NavigationDrawer_UserQuery["user"]>;
type UserOrg = NonNullable<UserInfos["active_org"]>["org"];
type UserProject = NonNullable<UserInfos["projects"]>[number]["project"];
type GroupProjectList = NonNullable<
  NavigationDrawer_OrgQuery["org"]
>["project_groups"];
type OrgSettings = NonNullable<NavigationDrawer_OrgQuery["org"]>["settings"];

const useNavigationDrawer = createComponentHook(() => {
  const { breakpoint } = useBreakpoint();
  const [open, setOpen] = React.useState(false);
  const [keyboardOpen, setKeyboardOpen] = React.useState(false);
  const location = useLocation();
  const matchedUrl = matchPath("/project/:projectId/*", location.pathname);
  let activeProjectId;
  if (matchedUrl) {
    const { projectId } = matchedUrl?.params as { projectId: string };
    activeProjectId = projectId;
  }
  const { user } = useUser();

  const currentUserOrgId = user.active_org?.org.id;

  const queryContext = React.useMemo(
    () => ({ additionalTypenames: ["orgs", "projects"] }),
    []
  );

  const [{ data: orgData }] = useNavigationDrawer_OrgQuery({
    variables: { orgId: currentUserOrgId! },
  });

  /* Getting more datas from the user */
  const [{ data: userData }] = useNavigationDrawer_UserQuery({
    context: queryContext,
    variables: { id: user?.id },
  });

  const userInfos = userData?.user;
  const projects = userData?.user?.projects.map((p) => p.project) ?? [];
  const org = userData?.user?.active_org?.org;
  const originalInviter =
    userData?.user?.originalInviter[0]?.inviter ?? undefined;

  /* Fetch the projects groups from the org, if the user has an org */
  const groupProjectList: GroupProjectList = orgData?.org?.project_groups ?? [];

  /* Fetch the org settings to see if projects should be goruped or not */
  const orgSettings: OrgSettings = orgData?.org?.settings;

  const handleHaptic = async () => {
    await hapticsImpact(ImpactStyle.Light);
  };

  React.useEffect(() => {
    let showListener: PluginListenerHandle;
    let hideListener: PluginListenerHandle;
    async function hideOnChatKeyboard() {
      if (Capacitor.isPluginAvailable("Keyboard")) {
        showListener = await Keyboard.addListener("keyboardWillShow", () => {
          setKeyboardOpen(true);
        });
        hideListener = await Keyboard.addListener("keyboardDidHide", () => {
          setKeyboardOpen(false);
        });
      }
    }
    void hideOnChatKeyboard();
    return () => {
      async function cleanListeners() {
        if (showListener) {
          await showListener.remove();
        }
        if (hideListener) {
          await hideListener.remove();
        }
      }
      void cleanListeners();
    };
  }, []);

  return {
    actions: {
      handleHaptic,
      setOpen,
    },
    state: {
      activeOrg: org,
      activeProjectId,
      groupProjectList,
      keyboardOpen,
      open,
      orgSettings,
      originalInviter,
      projects,
      showSettingsDrawer: !keyboardOpen && breakpoint === "mobile",
      user: userInfos,
    },
  };
});

// TODO: Properly refactor to have only one single org
// Remove dropdown, remove notifications aggregation logic, ...
function NavigationDrawer(props: { children: React.ReactNode }) {
  const { actions, state } = useNavigationDrawer();

  // TODO: Remove this log when problem is fixed
  console.log("NavigationDrawer state", state);

  if (!state.user) {
    return null;
  }

  return (
    <Drawer open={state.open} setOpen={actions.setOpen}>
      <DrawerContent
        position="left"
        safeAreaTop={false}
        safeAreaBottom={false}
        background={false}
      >
        <Box
          display="grid"
          css={{
            gridTemplate: "1fr auto / 1fr",
            height: "100%",
            maxWidth: "360px",
            width: "calc(100vw - 20px)",
          }}
        >
          <Box
            overflowY="auto"
            width="full"
            background="navyDark"
            display="flex"
            flexDirection="column"
            css={{
              overflowY: "auto", // This enables vertical scrolling
            }}
          >
            <NavigationDrawerHeader
              activeOrg={state.activeOrg}
              originalInviter={state.originalInviter}
              keyboardOpen={state.keyboardOpen}
            />
            {state.projects && (
              <ProjectListMenu
                closeDrawer={() => actions.setOpen(false)}
                projects={state.projects}
                activeProjectId={state.activeProjectId}
                groupProjectList={state.groupProjectList}
                orgSettings={state.orgSettings}
              />
            )}
          </Box>
          {state.showSettingsDrawer && (
            <SettingsDrawer
              closeDrawer={() => actions.setOpen(false)}
              orgs={state.activeOrg ? [state.activeOrg] : []}
              activeOrgId={state.user.active_org?.org.id}
            >
              <DrawerTrigger asChild>
                <Box width="full" onClick={actions.handleHaptic}>
                  <SettingsDrawerButton user={state.user} />
                </Box>
              </DrawerTrigger>
            </SettingsDrawer>
          )}
        </Box>
      </DrawerContent>
      {props.children}
    </Drawer>
  );
}

export type { GroupProjectList, OrgSettings, UserInfos, UserOrg, UserProject };
export { NavigationDrawer, useNavigationDrawer };
