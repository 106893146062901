import * as React from "react";
import { styled } from "~/config/stitches";
import { Box } from "../Box/Box";
import { Column } from "../Columns/Column";
import { Columns } from "../Columns/Columns";
import { Text } from "../Text/Text";

const LIST_ITEM_HEIGHT = 81;

type ListItemProps = {
  /* if you have notification to display you MUST HAVE avatar */
  avatar?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactNode;
  notification?: React.ReactNode;
  disabled?: boolean;
};

const AvatarWrapper = styled("div", {
  display: "flex",
  position: "relative",
});

function ListItem(props: ListItemProps) {
  return (
    <Box css={{ opacity: props.disabled ? "15%" : undefined }}>
      <Columns
        space="small"
        alignY="center"
        css={{ justifyContent: "space-between", maxWidth: "80%" }}
      >
        {props.avatar ? (
          <Column width="3-5" space="large">
            <Box
              display="flex"
              justifyContent="spaceBetween"
              gap="xsmall"
              alignItems="center"
            >
              <AvatarWrapper>
                {props.notification}
                {props.avatar}
              </AvatarWrapper>
              <Box
                width="full"
                css={{
                  alignItems: "start",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
                alignItems="center"
              >
                <Text align="left" weight="medium">
                  {props.title}
                </Text>
                <Text align="left" tone="secondary">
                  {props.subtitle}
                </Text>
              </Box>
            </Box>
          </Column>
        ) : (
          <Column width="3-5" space="large">
            <Box
              width="full"
              css={{
                alignItems: "start",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "scroll",
              }}
            >
              <Text align="left" weight="medium">
                {props.title}
              </Text>
              <Text align="left" tone="secondary">
                {props.subtitle}
              </Text>
            </Box>
          </Column>
        )}
        <Column
          css={{
            alignItems: "end",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: "80px",
            width: "100%",
          }}
        >
          {props.action}
        </Column>
      </Columns>
    </Box>
  );
}

export type { ListItemProps };
export { LIST_ITEM_HEIGHT, ListItem };
