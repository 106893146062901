import { z } from "zod";
import { FileApprovalStatusId } from "../status";

const validationSchema = z.object({
  comment: z.string().min(1).nullable().optional(),
  file_version_id: z.string().uuid(),
  status: z.nativeEnum(FileApprovalStatusId),
});

export { validationSchema };
