import { validationSchema } from "@clovis/server/src/app/domain/file/approvals/add-user-approval-to-file-version/add-user-approval-to-file-version.validation";
import { FileApprovalStatusId } from "@clovis/server/src/app/domain/file/approvals/status";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { nullable, useForm } from "~/config/react-hook-form";
import type { ButtonProps } from "~/design-system/Button";
import { Button } from "~/design-system/Button";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { TextField } from "~/design-system/TextField";
import { createComponentHook } from "~/types";
import { useAddUserApprovalToFileVersionMutation } from "./AddApprovalForm.graphql";

const MODE_DEFAULT_STATUS_MAP = {
  deny: FileApprovalStatusId.DENIED,
  nc: FileApprovalStatusId.NC,
  validate: FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS,
  validateWithComments: FileApprovalStatusId.APPROVED_WITH_COMMENTS,
};

const BUTTON_COLOR_STATUS_MAP: Record<
  FileApprovalStatusId,
  ButtonProps["tone"]
> = {
  [FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS]: "brandAccent",
  [FileApprovalStatusId.APPROVED_WITH_COMMENTS]: "caution",
  [FileApprovalStatusId.DENIED]: "critical",
  [FileApprovalStatusId.NC]: "brandAccent",
};

const useAddApprovalForm = createComponentHook(
  (props: AddApprovalFormProps) => {
    const { t } = useTranslation();

    let leadingIcon;
    if (props.mode === "deny") {
      leadingIcon = <XIcon />;
    }
    if (props.mode === "validateWithComments") {
      leadingIcon = <CheckIcon />;
    }

    const STATUS_TO_BUTTON_MESSAGE_MAP = {
      [FileApprovalStatusId.NC]: t(
        "screens.AddApprovalForm.submitButtonText.NC",
        "I'm not concerned"
      ),
      [FileApprovalStatusId.DENIED]: t(
        "screens.AddApprovalForm.submitButtonText.DENIED",
        "Deny"
      ),
      [FileApprovalStatusId.APPROVED_WITH_COMMENTS]: t(
        "screens.AddApprovalForm.submitButtonText.APPROVED_WITH_COMMENTS",
        "Approve with Comments"
      ),
      [FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS]: t(
        "screens.AddApprovalForm.submitButtonText.APPROVED_WITHOUT_COMMENTS",
        "Approve"
      ),
    };

    const displayComment = props.mode !== "validate";

    const [readyToSubmit, setReadyToSubmit] = React.useState(
      props.mode === "nc" || props.mode === "validate"
    );

    const form = useForm(validationSchema, {
      defaultValues: {
        file_version_id: props.fileVersionId,
        status: MODE_DEFAULT_STATUS_MAP[props.mode],
      },
    });

    const watchStatus = form.watch("status");
    const watchComments = form.watch("comment");

    const hasComments = Boolean(watchComments);

    React.useEffect(() => {
      setReadyToSubmit(true);
      form.clearErrors();
      if (
        (props.mode === "deny" && hasComments === false) ||
        (props.mode === "validateWithComments" && hasComments === false)
      ) {
        setReadyToSubmit(false);
        form.setError("comment", {
          message: t(
            "screens.AddApprovalForm.nonEmptyCommentError",
            "You must add a comment"
          ),
        });
      }
    }, [hasComments, props.mode]);

    const [, addUserApprovalToFileVersion] =
      useAddUserApprovalToFileVersionMutation();

    const handleSubmit = form.handleSubmit(async (input) => {
      // If there is an empty string in the comment input dismiss the comment
      const { data } = await addUserApprovalToFileVersion(
        {
          input: input,
        },
        {
          additionalTypenames: ["file_approvals", "files"],
        }
      );
      if (
        data?.addUserApprovalToFileVersion?.__typename ===
        "AddUserApprovalToFileVersionSuccess"
      ) {
        toast.success(
          t(
            "screens.AddApprovalForm.addUserApprovalToFileVersionSuccessToast",
            "Your approval has been added to the file version"
          )
        );
        props.onSuccess?.(props.fileVersionId);
      } else {
        toast.error(
          t(
            "screens.AddApprovalForm.addUserApprovalToFileVersionErrorToast",
            "An error occured while trying to add your approval"
          )
        );
      }
    });

    return {
      actions: {
        handleSubmit,
        registerInput: form.register,
      },
      state: {
        displayComment,
        errors: form.formState.errors,
        leadingIcon,
        readyToSubmit,
        status: watchStatus,
        submitColor: BUTTON_COLOR_STATUS_MAP[watchStatus],
        submitMessage: STATUS_TO_BUTTON_MESSAGE_MAP[watchStatus],
      },
      t,
    };
  }
);

type AddApprovalFormProps = {
  fileVersionId: string;
  mode: "deny" | "nc" | "validate" | "validateWithComments";
  onSuccess?: (fileVersionId: string) => unknown;
};

function AddApprovalForm(props: AddApprovalFormProps) {
  const { actions, state, t } = useAddApprovalForm(props);
  return (
    <Form onSubmit={actions.handleSubmit}>
      <Stack space="gutter">
        {state.displayComment && (
          <TextField
            {...actions.registerInput("comment", {
              setValueAs: (v: string) => nullable(v.trim()),
            })}
            label={t("screens.AddApprovalForm.commentsLabel", "Comments")}
            error={state.errors.comment?.message}
            multiline
            rows={4}
            // override the zod schema to remove the "(optional)" text
            required={
              state.status === FileApprovalStatusId.DENIED ||
              state.status === FileApprovalStatusId.APPROVED_WITH_COMMENTS
            }
            dataIntercomTarget="file-approval-mode-comment"
          />
        )}
        <Button
          leadingIcon={state.leadingIcon}
          disabled={!state.readyToSubmit}
          size="large"
          type="submit"
          tone={state.submitColor}
          width="full"
          variant="solid"
          dataIntercomTarget="file-viewer-add-approval-submit"
        >
          {state.submitMessage}
        </Button>
      </Stack>
    </Form>
  );
}

export type { AddApprovalFormProps };
export { AddApprovalForm };
