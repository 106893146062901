import * as React from "react";
import { Box } from "~/design-system/Box";
import { Icon } from "~/design-system/Icon";
import { Text } from "~/design-system/Text";

type IconAndTextLabelProps = {
  icon?: React.ReactNode;
  text: string;
};

function IconAndTextLabel(props: IconAndTextLabelProps) {
  return (
    <Box
      flexDirection="row"
      css={{
        alignItems: "baseline",
        display: "inline-flex",
        height: "18px",
      }}
      gap="xxsmall"
    >
      {props.icon && (
        <Icon
          label="Filter icon"
          size={"xsmall"}
          css={{
            marginBottom: "1px",
            marginTop: "auto",
          }}
        >
          {props.icon}
        </Icon>
      )}
      <Text size={"small"}>{props.text}</Text>
    </Box>
  );
}

export { IconAndTextLabel };
