import * as React from "react";
import type { BasicMultiSelectFieldInputProps } from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import {
  BasicMultiSelectFieldInput,
  BasicMultiSelectFieldInputOptionType,
} from "~/screens/App/components/BasicMultiSelectFieldInput/BasicMultiSelectFieldInput";
import { createComponentHook } from "~/types";
import { useGetProjectsQuery } from "./ProjectsMultiSelectFieldInput.graphql";

const useProjectMultiSelectFieldInput = createComponentHook(() => {
  const queryContext = React.useMemo(
    () => ({ additionalTypenames: ["projects"] }),
    []
  );
  const [{ data }] = useGetProjectsQuery({ context: queryContext });
  return {
    state: {
      projects:
        data?.projects.map((p) => ({
          avatar: p.avatar,
          label: p.name,
          type: BasicMultiSelectFieldInputOptionType.PROJECT,
          value: p.id,
        })) ?? [],
    },
  };
});

const ProjectsMultiSelectFieldInput = React.forwardRef<
  HTMLInputElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Omit<BasicMultiSelectFieldInputProps<any>, "options">
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(function ProjectsMultiSelectFieldInput(props, _) {
  const { state } = useProjectMultiSelectFieldInput();

  return <BasicMultiSelectFieldInput options={state.projects} {...props} />;
});

export { ProjectsMultiSelectFieldInput };
