import type { TemplateTypeId } from "@clovis/server/src/app/domain/project/template/types";
import { FocusScope } from "@radix-ui/react-focus-scope";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Undraw from "react-undraw";
import { z } from "zod";
import { useForm } from "~/config/react-hook-form";
import { colors } from "~/config/stitches/colors";
import { useUser } from "~/config/user/UserProvider";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Form } from "~/design-system/Form";
import { useBreakpoint } from "~/design-system/hooks";
import { SelectField } from "~/design-system/SelectField";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { getI18nTemplate } from "~/screens/App/screens/Project/utils";
import {
  useCopyProjectMutation,
  useCreateDemoProjectFormQuery,
} from "./CreateDemoProjectForm.graphql";

type CreateDemoProjectFormProps = {
  onSuccess?: (projectId: string) => void;
};

const validationSchema = z.object({
  projectId: z.string().uuid(),
});

const useCreateDemoProjectForm = (props: CreateDemoProjectFormProps) => {
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();
  const form = useForm(validationSchema);
  const { user } = useUser();
  const [, copyProject] = useCopyProjectMutation();
  const [{ data }] = useCreateDemoProjectFormQuery({
    variables: {
      language: user.language,
    },
  });

  const handleSubmit = form.handleSubmit(async (input) => {
    const { data } = await copyProject(
      {
        input: {
          isDemoProject: true,
          options: {
            labels: true,
            members: true,
            publicDocuments: true,
            restrictedDocuments: true,
            tasks: true,
            tasksComments: true,
            teams: true,
          },
          projectId: input.projectId,
        },
      },
      { additionalTypenames: ["projects"] }
    );

    if (data?.copyProject?.__typename === "CopyProjectSuccess") {
      toast.success(
        <span data-intercom-target="demo-project-created">
          {t(
            "components.CreateDemoProjectDialog.CreateDemoProjectForm.copyProjectSuccessToast",
            "Your demo project has been created"
          )}
        </span>
      );
      form.reset();
      props.onSuccess?.(data.copyProject.project_id);
    } else {
      toast.error(
        t(
          "components.CreateDemoProjectDialog.CreateDemoProjectForm.copyProjectErrorToast",
          "Something went wrong while creating your demo project"
        )
      );
    }
  });

  return {
    actions: { handleSubmit, registerInput: form.register },
    state: {
      breakpoint,
      control: form.control,
      errors: form.formState.errors,
      isSubmitting: form.formState.isSubmitting,
      templates: data?.templates,
    },
    t,
  };
};

function CreateDemoProjectForm(props: CreateDemoProjectFormProps) {
  const { actions, state, t } = useCreateDemoProjectForm(props);

  if (!state.templates || state.templates.length === 0) {
    return (
      <Stack align="center" space="small">
        <Box style={{ maxHeight: "30vh" }} width="full" height="full">
          <Undraw name="empty" primaryColor={colors.emerald500} height="100%" />
        </Box>
        <Text>
          {t(
            "components.CreateDemoProjectDialog.CreateDemoProjectForm.empty",
            "No templates available at the moment"
          )}
        </Text>
      </Stack>
    );
  }

  return (
    <FocusScope asChild loop trapped>
      <Form onSubmit={actions.handleSubmit}>
        <Stack space="gutter">
          <Text tone="neutral" italic>
            {t(
              "components.CreateDemoProjectDialog.CreateDemoProjectForm.subtitle",
              "Choose the template from which you want to create your demo project. Each template is adapted according to the industry."
            )}
          </Text>
          <SelectField
            {...actions.registerInput("projectId")}
            label={t(
              "components.CreateDemoProjectDialog.CreateDemoProjectForm.templateLabel",
              "Template"
            )}
            error={state.errors.projectId?.message}
            required
            dataIntercomTarget="demo-project-template"
          >
            {state.templates.map((template, index) => (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <option key={index} value={template.id!}>
                {getI18nTemplate(template.type as TemplateTypeId, t)}
              </option>
            ))}
          </SelectField>
          <Button
            dataIntercomTarget="create-demo-project"
            size="large"
            tone="brandAccent"
            width="full"
            loading={state.isSubmitting}
          >
            {t(
              "components.CreateDemoProjectDialog.CreateDemoProjectForm.submitButton",
              "Create demo project"
            )}
          </Button>
        </Stack>
      </Form>
    </FocusScope>
  );
}

export { CreateDemoProjectForm };
