import type {
  IProjectNotification,
  IProjectNotificationPayload,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";
import {
  IProjectNotificationTypePrefix,
  ProjectNotificationNamespace,
} from "@clovis/server/src/app/domain/notification/create-notification/project/types";

interface IProjectInfo {
  id: string;
}

interface IProjectInfoNotificationPayload extends IProjectNotificationPayload {
  project: IProjectInfo;
}

const IProjectInfoNotificationTypePrefix =
  `${IProjectNotificationTypePrefix}/${ProjectNotificationNamespace.Info}` as const;

interface IProjectInfoNotification extends IProjectNotification {
  type: `${typeof IProjectInfoNotificationTypePrefix}/${string}`;
  readonly payload: IProjectInfoNotificationPayload;
}

export type { IProjectInfoNotification, IProjectInfoNotificationPayload };
export { IProjectInfoNotificationTypePrefix };
