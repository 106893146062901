import tw from "twin.macro";
import { styled } from "~/config/stitches";

const Placeholder = styled("div", {
  ...tw`bg-neutral-200`,

  defaultVariants: {
    borderRadius: "medium",
    height: "small",
    width: "medium",
  },

  variants: {
    background: {
      body: tw`bg-transparent`,
      brand: tw`bg-emerald-500`,
      brandAccent: tw`bg-emerald-700`,
      brandAccentActive: tw`bg-emerald-800`,
      brandAccentHover: tw`bg-emerald-600`,
      card: tw`bg-white`,
      caution: tw`bg-caution-400`,
      cautionLight: tw`bg-caution-100`,
      critical: tw`bg-critical-600`,
      criticalActive: tw`bg-critical-700`,
      criticalHover: tw`bg-critical-500`,
      criticalLight: tw`bg-critical-100`,
      formAccent: tw`bg-info-700`,
      formAccentActive: tw`bg-info-800`,
      formAccentDisabled: tw`bg-gray-300`,
      formAccentHover: tw`bg-info-600`,
      image: {
        "&": {
          position: "relative",
          zIndex: 0,
        },
        // We use the last-child because cypress will consider that the background
        // is "hidding" the actual content
        "& > :last-child": {
          bottom: 0,
          // Set container height/width to allow TwicPics to guess the image size context
          left: 0,
          position: "absolute",
          right: 0,
          top: 0,
          width: "100%",
          // Take the same zIndex as the current box
          zIndex: -1,
        },
      },
      info: tw`bg-blue-400`,
      infoLight: tw`bg-blue-200`,
      input: tw`bg-white`,
      inputDisabled: tw`bg-gray-200`,
      navy: tw`bg-slate-800`,
      navyDark: tw`bg-slate-900`,
      neutral: tw`bg-gray-300`,
      neutralLight: tw`bg-gray-100`,
      positive: tw`bg-positive-500`,
      positiveLight: tw`bg-positive-100`,
      promote: tw`bg-promote-500`,
      promoteLight: tw`bg-promote-200`,
      selection: tw`bg-gray-100`,
      transparent: tw`bg-transparent`,
    },
    borderRadius: {
      full: tw`rounded-full`,
      medium: tw`rounded-3xl`,
      none: tw`rounded-none`,
      small: tw`rounded-xl`,
      standard: tw`rounded-lg`,
    },
    flexGrow: {
      0: tw`flex-grow-0`,
      1: tw`flex-grow`,
    },
    flexShrink: {
      0: tw`flex-shrink-0`,
    },
    height: {
      full: tw`h-full`,
      large: tw`h-32`,
      medium: tw`h-20`,
      small: tw`h-12`,
      xlarge: tw`h-48`,
      xsmall: tw`h-5`,
    },
    margin: {
      gutter: {
        margin: "$gutter",
      },
      large: {
        margin: "$large",
      },
      medium: {
        margin: "$medium",
      },
      small: {
        margin: "$small",
      },
      xlarge: {
        margin: "$xlarge",
      },
      xsmall: {
        margin: "$xsmall",
      },
      xxlarge: {
        margin: "$xxlarge",
      },
      xxsmall: {
        margin: "$xxsmall",
      },
    },
    marginBottom: {
      gutter: {
        marginBottom: "$gutter",
      },
      large: {
        marginBottom: "$large",
      },
      medium: {
        marginBottom: "$medium",
      },
      small: {
        marginBottom: "$small",
      },
      xlarge: {
        marginBottom: "$xlarge",
      },
      xsmall: {
        marginBottom: "$xsmall",
      },
      xxlarge: {
        marginBottom: "$xxlarge",
      },
      xxsmall: {
        marginBottom: "$xxsmall",
      },
    },
    marginLeft: {
      gutter: {
        marginLeft: "$gutter",
      },
      large: {
        marginLeft: "$large",
      },
      medium: {
        marginLeft: "$medium",
      },
      small: {
        marginLeft: "$small",
      },
      xlarge: {
        marginLeft: "$xlarge",
      },
      xsmall: {
        marginLeft: "$xsmall",
      },
      xxlarge: {
        marginLeft: "$xxlarge",
      },
      xxsmall: {
        marginLeft: "$xxsmall",
      },
    },
    marginRight: {
      gutter: {
        marginRight: "$gutter",
      },
      large: {
        marginRight: "$large",
      },
      medium: {
        marginRight: "$medium",
      },
      small: {
        marginRight: "$small",
      },
      xlarge: {
        marginRight: "$xlarge",
      },
      xsmall: {
        marginRight: "$xsmall",
      },
      xxlarge: {
        marginRight: "$xxlarge",
      },
      xxsmall: {
        marginRight: "$xxsmall",
      },
    },
    marginTop: {
      gutter: {
        marginTop: "$gutter",
      },
      large: {
        marginTop: "$large",
      },
      medium: {
        marginTop: "$medium",
      },
      small: {
        marginTop: "$small",
      },
      xlarge: {
        marginTop: "$xlarge",
      },
      xsmall: {
        marginTop: "$xsmall",
      },
      xxlarge: {
        marginTop: "$xxlarge",
      },
      xxsmall: {
        marginTop: "$xxsmall",
      },
    },
    marginX: {
      gutter: {
        marginX: "$gutter",
      },
      large: {
        marginX: "$large",
      },
      medium: {
        marginX: "$medium",
      },
      small: {
        marginX: "$small",
      },
      xlarge: {
        marginX: "$xlarge",
      },
      xsmall: {
        marginX: "$xsmall",
      },
      xxlarge: {
        marginX: "$xxlarge",
      },
      xxsmall: {
        marginX: "$xxsmall",
      },
    },
    marginY: {
      gutter: {
        marginY: "$gutter",
      },
      large: {
        marginY: "$large",
      },
      medium: {
        marginY: "$medium",
      },
      small: {
        marginY: "$small",
      },
      xlarge: {
        marginY: "$xlarge",
      },
      xsmall: {
        marginY: "$xsmall",
      },
      xxlarge: {
        marginY: "$xxlarge",
      },
      xxsmall: {
        marginY: "$xxsmall",
      },
    },
    maxWidth: {
      large: tw`max-w-7xl`,
      medium: tw`max-w-4xl`,
      small: tw`max-w-2xl`,
      xsmall: tw`max-w-md`,
      xxsmall: tw`max-w-sm`,
    },
    minWidth: {
      0: tw`min-w-0`,
    },
    width: {
      full: tw`w-full`,
      large: tw`w-32`,
      medium: tw`w-20`,
      small: tw`w-12`,
      xlarge: tw`w-48`,
      xsmall: tw`w-5`,
    },
  },
});

type PlaceholderProps = React.ComponentProps<typeof Placeholder>;

export type { PlaceholderProps };
export { Placeholder };
