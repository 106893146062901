import * as React from "react";
import { useNavigate } from "react-router";
import { useUser } from "~/config/user/UserProvider";
import { Box } from "~/design-system/Box";
import type { ControlledDrawerProps } from "~/design-system/Drawer";
import { Drawer, DrawerContent } from "~/design-system/Drawer";
import { CreateProjectForm } from "./components/CreateProjectForm/CreateProjectForm";
import { DrawerHeader } from "./components/DrawerHeader/DrawerHeader";
import { OrgRequiredMessage } from "./components/OrgRequiredMessage";

type CreateProjectDrawerProps = {
  onSuccess?: (projectId: string) => void;
  /* children is not required ONLY if controlled */
  children?: React.ReactNode;
  controlled?: Pick<ControlledDrawerProps, "open" | "setOpen">;
};

const useCreateProjectDrawer = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const defaultOnSuccess = (projectId: string) => {
    navigate(`/project/${projectId}`);
  };
  return {
    actions: {
      defaultOnSuccess,
    },
    state: {
      userHasOrg: !!user.active_org,
    },
  };
};

function CreateProjectDrawer(props: CreateProjectDrawerProps) {
  const { actions, state } = useCreateProjectDrawer();

  const drawerContent = (
    <DrawerContent position="right">
      <Box padding="gutter" height="full">
        {state.userHasOrg ? (
          <Box display="flex" flexDirection="column" height="full" gap="gutter">
            <DrawerHeader />
            <CreateProjectForm
              onSuccess={props.onSuccess ?? actions.defaultOnSuccess}
            />
          </Box>
        ) : (
          <OrgRequiredMessage />
        )}
      </Box>
    </DrawerContent>
  );

  if (props.controlled) {
    return (
      <Drawer open={props.controlled.open} setOpen={props.controlled.setOpen}>
        {drawerContent}
        {props.children}
      </Drawer>
    );
  } else {
    return (
      <Drawer>
        {drawerContent}
        {props.children}
      </Drawer>
    );
  }
}

export type { CreateProjectDrawerProps };
export { CreateProjectDrawer };
