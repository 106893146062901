import * as Dialog from "@radix-ui/react-dialog";
import type * as Stitches from "@stitches/react";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import tw from "twin.macro";
import type { CSS } from "~/config/stitches";
import { styled } from "~/config/stitches";
import { useUser } from "~/config/user/UserProvider";
import { isClovisTeamIntercomUser } from "../Dialog";
import { SafeArea } from "../SafeArea";

const StyledOverlay = styled(motion.div, {
  ...{
    bottom: 0,
    left: 0,
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 9990,
  },
  defaultVariants: {
    display: true,
  },
  variants: {
    display: {
      true: {
        backgroundColor: "rgba(0, 0, 0, .15)",
      },
    },
  },
});

const StyledContent = styled(motion.div, {
  ...{
    "&:focus": {
      outline: "none",
    },
    bottom: "0",
    maxWidth: "100vw",
    minWidth: 200,
    position: "fixed",
    textAlign: "left",
    top: "0",
    zIndex: 9990,
  },
  ...tw`shadow-sm`,

  defaultVariants: {
    background: true,
    scrollable: true,
  },

  variants: {
    background: {
      true: { backgroundColor: "white" },
    },
    position: {
      left: {
        left: 0,
      },
      right: {
        right: 0,
      },
    },
    scrollable: {
      false: {
        overflowY: "none",
      },
      true: {
        overflowY: "auto",
      },
    },
  },
});

type DrawerContextProps = { open: boolean; setOpen: (open: boolean) => void };

const DrawerContext = React.createContext<DrawerContextProps>({
  open: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpen: () => {},
} as DrawerContextProps);

const useDrawerContext = () => React.useContext(DrawerContext);

type UncontrolledDrawerProps = {
  children: React.ReactNode;
  open?: never;
  setOpen?: never;
};

type ControlledDrawerProps = {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DrawerRoot = (props: ControlledDrawerProps) => (
  <DrawerContext.Provider value={{ open: props.open, setOpen: props.setOpen }}>
    <Dialog.Root open={props.open} onOpenChange={props.setOpen}>
      {props.children}
    </Dialog.Root>
  </DrawerContext.Provider>
);

type DrawerProps = ControlledDrawerProps | UncontrolledDrawerProps;

const Drawer = (props: DrawerProps) => {
  const [open, setOpen] = React.useState(false);

  if (props.open !== undefined) {
    return <DrawerRoot {...props} />;
  }

  return <DrawerRoot open={open} setOpen={setOpen} {...props} />;
};

const DrawerTrigger = Dialog.Trigger;
const DrawerClose = Dialog.Close;

type DrawerContentProps = Stitches.VariantProps<typeof StyledContent> & {
  children: React.ReactNode;
  css?: CSS;
  // You want to use this option when your DialogContent is in the
  // subtree of another element with an onClick listener on it
  stopClickPropagation?: boolean;
  /* by default is set to true, but not needed for some 
  specific drawer which got their own safe area logic */
  safeAreaTop?: boolean;
  safeAreaBottom?: boolean;
  /* allows additional functions on drawer close */
  onClose?: () => void;
  background?: boolean;
  displayOverlay?: boolean;
  closeOnOverlayClick?: boolean;
};

const stopClickPropagationHandler = (event: React.MouseEvent<unknown>) => {
  event.stopPropagation();
};

const DrawerContent = (props: DrawerContentProps) => {
  const [dragDirection, setDragDirection] = React.useState<"x" | "y">();
  const { open, setOpen } = useDrawerContext();
  const { user } = useUser();

  const initialCloseOnOverlayClick =
    user.full_name && isClovisTeamIntercomUser(user.full_name) ? false : true;

  const {
    children,
    closeOnOverlayClick = initialCloseOnOverlayClick,
    displayOverlay = true,
    position = "left",
    safeAreaBottom = true,
    safeAreaTop = true,
    stopClickPropagation = true,
    ...contentProps
  } = props;

  return (
    <div style={{ position: "fixed", right: 0, top: 0, zIndex: 4 }}>
      <AnimatePresence>
        {open && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay
              forceMount
              asChild
              onClick={(e) => {
                if (closeOnOverlayClick) {
                  props.onClose?.();
                  setOpen(false);
                } else {
                  e.preventDefault();
                }
              }}
            >
              <StyledOverlay
                onClick={
                  stopClickPropagation ? stopClickPropagationHandler : undefined
                }
                display={displayOverlay}
              >
                {/* We need to put the Content part into the Overlay part to fix scroll issue (See https://github.com/radix-ui/primitives/issues/1159) */}
                <Dialog.Content
                  onEscapeKeyDown={props.onClose}
                  onPointerDownOutside={(e) => {
                    if (closeOnOverlayClick) {
                      props.onClose?.();
                    } else {
                      e.preventDefault();
                    }
                  }}
                  onInteractOutside={(e) => {
                    if (closeOnOverlayClick) {
                      props.onClose?.();
                    } else {
                      e.preventDefault();
                    }
                  }}
                  forceMount
                  onClick={
                    stopClickPropagation
                      ? stopClickPropagationHandler
                      : undefined
                  }
                >
                  <StyledContent
                    {...contentProps}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    drag="x"
                    dragElastic={{ left: 0.5, right: 0.000001 }}
                    dragConstraints={{ left: 0, right: 0 }}
                    dragDirectionLock
                    onDirectionLock={(axis) => {
                      setDragDirection(axis);
                    }}
                    onDragEnd={(_, info) => {
                      if (
                        dragDirection === "x" &&
                        info.offset.x < -100 &&
                        position === "left"
                      ) {
                        setOpen(false);
                      }
                      if (
                        dragDirection === "x" &&
                        info.offset.x > 100 &&
                        position === "right"
                      ) {
                        setOpen(false);
                      }
                    }}
                    position={position}
                    variants={{
                      hidden: {
                        transition: {
                          duration: 0.3,
                          ease: "easeOut",
                        },
                        translateX:
                          props.position === "left" ? "-100%" : "100%",
                      },
                      show: {
                        transition: {
                          duration: 0.3,
                          ease: "easeOut",
                        },
                        translateX: "0%",
                      },
                    }}
                  >
                    {safeAreaTop && <SafeArea top />}
                    {children}
                    {safeAreaBottom && <SafeArea bottom />}
                  </StyledContent>
                </Dialog.Content>
              </StyledOverlay>
            </Dialog.Overlay>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </div>
  );
};

export type {
  ControlledDrawerProps,
  DrawerContextProps,
  DrawerProps,
  UncontrolledDrawerProps,
};
export { Drawer, DrawerClose, DrawerContent, DrawerTrigger, useDrawerContext };
