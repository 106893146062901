import type * as Types from '../../../../../../../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddUserApprovalToFileVersionMutationVariables = Types.Exact<{
  input: Types.AddUserApprovalToFileVersionInput;
}>;


export type AddUserApprovalToFileVersionMutation = { addUserApprovalToFileVersion?: { __typename: 'AddUserApprovalToFileVersionErrors' } | { __typename: 'AddUserApprovalToFileVersionSuccess' } | null | undefined };


export const AddUserApprovalToFileVersionDocument = gql`
    mutation addUserApprovalToFileVersion($input: AddUserApprovalToFileVersionInput!) {
  addUserApprovalToFileVersion(input: $input) {
    __typename
  }
}
    `;

export function useAddUserApprovalToFileVersionMutation() {
  return Urql.useMutation<AddUserApprovalToFileVersionMutation, AddUserApprovalToFileVersionMutationVariables>(AddUserApprovalToFileVersionDocument);
};