import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Auth0Config } from "~/config/auth0";
import { useAuth0 } from "~/config/auth0/use-auth0";
import { withAuthenticationRequired } from "~/config/auth0/with-authentication-required";
import { PushNotifications } from "~/config/capacitor/push-notifications/PushNotifications";
import { AppUrlListener } from "~/config/capacitor/router/router";
import { useTrackPage } from "~/config/segment/SegmentProvider";
import { SentryReact } from "~/config/sentry";
import { UppyConfig } from "~/config/uppy/UppyConfig";
import { UrqlConfig } from "~/config/urql";
import { UserProvider } from "~/config/user/UserProvider";
import { SpinLoader } from "~/design-system/Spinner/SpinLoader";
import { AnonymousApp } from "./AnonymousApp";
import { AuthenticatedLinkRedirect } from "./AuthenticatedLinkRedirect";
import { ErrorFallback } from "./components/ErrorFallback/ErrorFallback";
import { FilesUploader } from "./components/FilesUploader/FilesUploader";
import { FullPageSpinner } from "./components/FullPageSpinner/FullPageSpinner";
import { Layout } from "./components/Layout/Layout";
import { NotFound } from "./components/NotFound/NotFound";
import { RedirectAfterLogin } from "./components/RedirectAfterLogin/RedirectAfterLogin";
import { RedirectToMobileAppDialog } from "./components/RedirectMobileAppDialog/RedirectToMobileAppDialog";
import { TaskFilterProvider } from "./components/Tasks/TaskFilters/TaskFilterContext";
import { Toaster } from "./components/Toaster/Toaster";
import { UpdateAppMetadataInfos } from "./components/UpdateAppMetadataInfos/UpdateAppMetadataInfos";
import { UpdateBrowserInfos } from "./components/UpdateBrowserInfos/UpdateBrowserInfos";
import { RightsScreen } from "./screens/AnonRightsScreen/RightsScreen";
import { useRedirectAfterLogin } from "./utils/useRedirectAfterLogin/useRedirectAfterLogin";
// Contain the basic routes of our app
// Will be re-used in Layout to retrieve current params
// If you add more root routes to the app and want them to
// be accessible into the mobile app via deeplinks, you must
// add thems to the mobile build config as well
// see docs here: https://app.gitbook.com/o/-LlhZY09V4VTR7cy_55t/s/-MSitZZVNivUi5eDfPNe/guidelines/devices/mobile/how-to-add-new-deeplink-path-to-the-app
const APP_BASE_ROUTES_PATTERNS = {
  dashboard: "/dashboard/*",
  onboarding: "/onboarding",
  org: "/org/:orgId/*",
  project: "/project/:projectId/*",
  unavailable: "/unavailable",
  user_notifications: "/user/notifications",
  user_profile: "/user/profile",
};

const Dashboard = React.lazy(() =>
  import("./screens/Dashboard/Dashboard").then((m) => ({
    default: m.Dashboard,
  }))
);

const Org = React.lazy(() =>
  import("./screens/Org/Org").then((m) => ({
    default: m.Org,
  }))
);

const Project = React.lazy(() =>
  import("./screens/Project/Project").then((m) => ({
    default: m.Project,
  }))
);

const UserProfileScreen = React.lazy(() =>
  import("./screens/User/screens/UserProfileScreen/UserProfileScreen").then(
    (m) => ({
      default: m.UserProfileScreen,
    })
  )
);

const UserNotificationsScreen = React.lazy(() =>
  import(
    "./screens/User/screens/UserNotificationsScreen/UserNotificationsScreen"
  ).then((m) => ({
    default: m.UserNotificationsScreen,
  }))
);

const UnavailableScreen = React.lazy(() =>
  import("./screens/UnavailableScreen/UnavailableScreen").then((m) => ({
    default: m.UnavailableScreen,
  }))
);

const SelfOnboardingScreen = React.lazy(() =>
  import("./screens/SelfOnboardingScreen/SelfOnboardingScreen").then((m) => ({
    default: m.SelfOnboardingScreen,
  }))
);

const AuthenticatedApp = withAuthenticationRequired(
  function AuthenticatedApp() {
    return (
      <SentryReact.ErrorBoundary
        fallback={ErrorFallback}
        beforeCapture={(scope) => {
          scope.setTag("ScreenError", "AuthenticatedApp");
        }}
      >
        <UrqlConfig>
          <UserProvider>
            <RedirectAfterLogin>
              <PushNotifications>
                <UpdateBrowserInfos>
                  <UpdateAppMetadataInfos>
                    <UppyConfig>
                      <TaskFilterProvider projectFilters={{}}>
                        <Layout>
                          <SentryReact.ErrorBoundary
                            fallback={ErrorFallback}
                            beforeCapture={(scope) => {
                              scope.setTag("ScreenError", "LayoutError");
                            }}
                          >
                            <React.Suspense fallback={<SpinLoader />}>
                              <Routes>
                                <Route
                                  path="/"
                                  element={<Navigate to="/dashboard" replace />}
                                />
                                <Route
                                  path={APP_BASE_ROUTES_PATTERNS.dashboard}
                                  element={<Dashboard />}
                                />
                                <Route
                                  path={APP_BASE_ROUTES_PATTERNS.org}
                                  element={<Org />}
                                />
                                <Route
                                  path={APP_BASE_ROUTES_PATTERNS.project}
                                  element={<Project />}
                                />
                                <Route
                                  path={APP_BASE_ROUTES_PATTERNS.user_profile}
                                  element={<UserProfileScreen />}
                                />
                                <Route
                                  path={
                                    APP_BASE_ROUTES_PATTERNS.user_notifications
                                  }
                                  element={<UserNotificationsScreen />}
                                />
                                <Route
                                  path={APP_BASE_ROUTES_PATTERNS.unavailable}
                                  element={<UnavailableScreen />}
                                />
                                <Route
                                  path={APP_BASE_ROUTES_PATTERNS.onboarding}
                                  element={<SelfOnboardingScreen />}
                                />
                                <Route path="*" element={<NotFound />} />
                              </Routes>
                            </React.Suspense>
                            <RedirectToMobileAppDialog />
                          </SentryReact.ErrorBoundary>
                        </Layout>
                      </TaskFilterProvider>
                      <Toaster />
                      <FilesUploader />
                    </UppyConfig>
                  </UpdateAppMetadataInfos>
                </UpdateBrowserInfos>
              </PushNotifications>
            </RedirectAfterLogin>
          </UserProvider>
        </UrqlConfig>
      </SentryReact.ErrorBoundary>
    );
  }
);

// We need this intermediary app to handle auth0 login error
const Auth0ProtectedApp = () => {
  void useTrackPage();
  const { buildLogoutUrl, error } = useAuth0();
  const { saveCurrentURL } = useRedirectAfterLogin();

  React.useEffect(() => {
    // We register the current URL to retrieve it after Auth0 login
    saveCurrentURL();
  }, [saveCurrentURL]);

  // If there is an error it means the previous login attempt failed
  // this is where we break out of auth0 infinite login loop
  if (error) {
    // We need to call the auth0 logout route to clean the user session state
    // This gonna make the next redirect to our app end up on the "login" page
    const logoutUrl = buildLogoutUrl({ returnTo: window.location.origin });
    window.location.replace(logoutUrl);
    return <FullPageSpinner />;
  }

  return <AuthenticatedApp />;
};

const PublicApp = () => {
  return (
    <BrowserRouter>
      <AppUrlListener>
        <Auth0Config>
          <Routes>
            <Route path="/rights" element={<RightsScreen />} />
            {/* IMPORTANT! Make sure to change the auth0 rule for /register/userinfos if you change me */}
            <Route path="/anon/*" element={<AnonymousApp />} />
            {/* alink stand for 'AuthenticatedLink' */}
            <Route path="/alink" element={<AuthenticatedLinkRedirect />} />
            <Route path="*" element={<Auth0ProtectedApp />} />
          </Routes>
        </Auth0Config>
      </AppUrlListener>
    </BrowserRouter>
  );
};

const SentryApp = SentryReact.withErrorBoundary(PublicApp, {
  beforeCapture: (scope) => {
    scope.setTag("ScreenError", "PublicApp");
  },
  fallback: ErrorFallback,
});

export { SentryApp as App, APP_BASE_ROUTES_PATTERNS, ErrorFallback };
