import { AnimatePresence, motion } from "framer-motion";
import { Slot } from "nano-slots";
import * as React from "react";
import type { CSS } from "~/config/stitches";
import { Box } from "~/design-system/Box";
import { Column, Columns } from "~/design-system/Columns";
import { DrawerTrigger } from "~/design-system/Drawer";
import { hasNotch, SafeArea } from "~/design-system/SafeArea";
import { NavigationDrawerButton } from "~/screens/App/components/NavigationDrawer/components/NavigationDrawerButton/NavigationDrawerButton";
import { NavigationDrawer } from "~/screens/App/components/NavigationDrawer/NavigationDrawer";

type FooterProps = {
  css?: CSS;
};

function Footer(props: FooterProps) {
  return (
    <Box
      css={props.css}
      paddingX="xsmall"
      paddingY={hasNotch() ? undefined : "xsmall"}
    >
      <Box
        display="flex"
        justifyContent="evenly"
        alignItems="center"
        css={{ margin: "auto", width: "70%" }}
      >
        <Columns align="center">
          <Column width="1-3" alignY="center">
            <Box display="flex" justifyContent="center">
              <NavigationDrawer>
                <DrawerTrigger asChild>
                  <NavigationDrawerButton />
                </DrawerTrigger>
              </NavigationDrawer>
            </Box>
          </Column>
          <Column width="1-3" alignY="center">
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, x: -8 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -8 }}
                transition={{ duration: 0.3 }}
                key="Layout.Navigation"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Slot name="Layout.Navigation" />
              </motion.div>
            </AnimatePresence>
          </Column>
          <Column width="1-3" alignY="center">
            <motion.div
              initial={{ opacity: 0, x: -8 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -8 }}
              transition={{ delay: 0.3, duration: 0.3 }}
              key="Layout.Navigation"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Slot name="Layout.PrimaryAction" />
            </motion.div>
          </Column>
        </Columns>
      </Box>
      <SafeArea bottom />
    </Box>
  );
}

export { Footer };
