import AwsS3 from "@uppy/aws-s3";
import type { UppyFile, UppyOptions } from "@uppy/core";
import Uppy from "@uppy/core";
import { captureException } from "../sentry";
import { UppyImageCompressor } from "./plugins/image-compression";
import { createStore } from "./store";
import type { FileMeta } from "./types";

const { store, useStore: useUppy } = createStore();

const uppyConfig: UppyOptions = {
  autoProceed: true,
  logger: {
    debug: (...args) => {
      console.debug(args);
    },
    error: (...args) => {
      console.log(JSON.stringify(args, null, 2));
      captureException(args, {
        extra: {
          title: "Uppy error",
        },
      });
    },
    warn: (...args) => {
      captureException(args, {
        extra: {
          title: "Uppy warn",
        },
      });
    },
  },
  restrictions: {
    // 50 GB
    maxFileSize: 50e9,
  },
};

// ----------------------- Base Uppy -----------------------
const uppy = new Uppy({ ...uppyConfig, store });

uppy.use(UppyImageCompressor);

uppy.use(AwsS3, {
  async getUploadParameters(file) {
    return {
      method: "PUT",
      url: (file as UppyFile<FileMeta>).meta.url,
    };
  },

  // Add limit the number of uploads going on at the same time
  // to avoid timeout error when uploading multiples files
  // see: https://github.com/transloadit/uppy/issues/1190
  limit: 6,
});

export type { FileMeta };

export { uppy, uppyConfig, useUppy };
