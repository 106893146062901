/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
// TODO: document all this component and give storybook examples, especially about sorting
import ChevronDownIcon from "@heroicons/react/outline/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/react/outline/ChevronUpIcon";
import _ from "lodash";
import * as React from "react";
import type {
  Column,
  TableState,
  TableToggleAllRowsSelectedProps,
  TableToggleRowsSelectedProps,
} from "react-table";
import { useRowSelect, useSortBy, useTable } from "react-table";
import { useVirtual } from "react-virtual";
import type { CSS } from "~/config/stitches";
import { Box } from "~/design-system/Box";
import { useBreakpoint } from "~/design-system/hooks";
import { Icon } from "~/design-system/Icon";
import { Table } from "~/design-system/Table";
import type { SortType } from "../screens/Project/Docs/screens/ProjectDocumentsScreen/components/FilesFolders/components/FilesFoldersList/FilesSort.store";
import { StyledIndeterminateCheckbox } from "./IndeterminateCheckbox";

type rowProps<D> = {
  css?: CSS;
  onClick?: (row: D) => unknown;
};

type rowPropsGetter<D> = (row: D) => rowProps<D> | undefined;

type CheckBoxesRef = {
  [key: string]: React.RefObject<HTMLInputElement | null | undefined>;
};

type VirtualTableProps<D extends object> = {
  css?: CSS;
  noHeader?: boolean;
  data: Array<D>;
  columns: Array<Column<D>>;
  estimateSize?: (index: number) => number;
  initialState?: Partial<TableState<D>>;
  rowProps?: rowProps<D> | rowPropsGetter<D>;
  setSelectedItems?: (selectedItems: D[]) => void;
  canSelectItems?: boolean;
  setClearSelection?: (clearSelection: boolean) => void;
  clearSelection?: boolean;
  onSort?: (sortBy?: SortType) => void;
  manualSort?: boolean;
  setSortedDirection?: React.Dispatch<React.SetStateAction<boolean>>;
  rowWrapperComponent?: React.FC<{ data: D }>;
};

const useVirtualTable = <D extends object>(
  props: VirtualTableProps<D>,
  ref?: CheckBoxesRef
) => {
  const { breakpoint } = useBreakpoint();

  const {
    allColumns,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { sortBy },
    toggleAllRowsSelected,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: props.initialState,
      /* activate manualSortBy only if we pass our own sorting function */
      manualSortBy: props.manualSort ?? props.onSort !== undefined,
    },
    useSortBy,
    /* allow row selection with useRowSelect and the below rendering function */
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            Cell: ({
              row: { getToggleRowSelectedProps, index },
            }: {
              row: {
                getToggleRowSelectedProps: (
                  props?: Partial<TableToggleRowsSelectedProps> & {
                    ref?: React.RefObject<HTMLInputElement>;
                  }
                ) => TableToggleRowsSelectedProps & {
                  ref?: React.RefObject<HTMLInputElement>;
                };
                index: number;
              };
            }) => (
              <Box
                padding="xsmall"
                display="flex"
                justifyContent="center"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  getToggleRowSelectedProps?.().onChange?.(
                    e as unknown as React.ChangeEvent<HTMLElement>
                  );
                }}
              >
                <StyledIndeterminateCheckbox
                  size="xsmall"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                  }}
                  {...getToggleRowSelectedProps({
                    ref: ref?.[`checkbox${index + 1}`] as
                      | React.RefObject<HTMLInputElement>
                      | undefined,
                  })}
                />
              </Box>
            ),
            Header: ({
              getToggleAllRowsSelectedProps,
            }: {
              getToggleAllRowsSelectedProps: (
                props?: Partial<TableToggleAllRowsSelectedProps>
              ) => TableToggleAllRowsSelectedProps;
            }) => (
              <Box
                padding="xsmall"
                display="flex"
                justifyContent="center"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  getToggleAllRowsSelectedProps?.().onChange?.(
                    e as unknown as React.ChangeEvent<HTMLElement>
                  );
                }}
              >
                <StyledIndeterminateCheckbox
                  size="xsmall"
                  {...getToggleAllRowsSelectedProps()}
                />
              </Box>
            ),

            id: "selection",
            width: "48px",
          },
          ...columns,
        ];
      });
    }
  );

  /* on sortBy change, fire our sorting function */
  const { onSort } = props;
  React.useEffect(() => {
    let sort: SortType | undefined;
    if (sortBy?.[0]?.desc) {
      sort = "desc";
    } else if (sortBy?.[0]) {
      sort = "asc";
    } else {
      sort = undefined;
    }
    onSort?.(sort);
  }, [onSort, sortBy]);

  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtual({
    estimateSize: props.estimateSize,
    parentRef,
    size: rows.length,
  });

  /* check canSelectItems value and the selection column visibility */
  /* set visible/hidden if necessary */
  const selectionColumn = _.find(allColumns, ["id", "selection"]);
  if (selectionColumn?.isVisible && !props.canSelectItems) {
    selectionColumn?.toggleHidden();
  } else if (!selectionColumn?.isVisible && props.canSelectItems) {
    selectionColumn?.toggleHidden();
  }

  /* hide for docsLabels column on tablet breakpoint */
  const docsLabelsColumn = _.find(allColumns, ["id", "docsLabels"]);
  if (breakpoint === "tablet" && docsLabelsColumn?.isVisible) {
    docsLabelsColumn.toggleHidden();
  } else if (breakpoint !== "tablet" && !docsLabelsColumn?.isVisible) {
    docsLabelsColumn?.toggleHidden();
  }

  const {
    canSelectItems,
    clearSelection,
    setClearSelection,
    setSelectedItems,
  } = props;
  /* update selectedFlatRows in store when changed */
  React.useEffect(() => {
    if (selectedFlatRows && canSelectItems) {
      const items = selectedFlatRows.map((item) => item.original);
      setSelectedItems?.(items);
    }
  }, [canSelectItems, selectedFlatRows, setSelectedItems]);

  /* Reset selected items */
  React.useEffect(() => {
    if (clearSelection) {
      toggleAllRowsSelected(false);
      setClearSelection?.(false);
    }
  }, [clearSelection, setClearSelection, toggleAllRowsSelected]);

  const paddingTop =
    rowVirtualizer.virtualItems.length > 0
      ? rowVirtualizer.virtualItems[0].start
      : 0;
  const paddingBottom =
    rowVirtualizer.virtualItems.length > 0
      ? rowVirtualizer.totalSize -
        rowVirtualizer.virtualItems[rowVirtualizer.virtualItems.length - 1].end
      : 0;

  return {
    actions: {
      getTableBodyProps,
      getTableProps,
      prepareRow,
    },
    refs: {
      parentRef,
    },
    state: {
      breakpoint,
      headerGroups,
      paddingBottom,
      paddingTop,
      rowVirtualizer,
      rows,
      selectedFlatRows,
    },
  };
};

const VirtualTable = React.forwardRef(
  VirtualTableRef as unknown as React.ForwardRefRenderFunction<
    unknown,
    VirtualTableProps<object>
  >
) as <T extends object>(
  props: VirtualTableProps<T> & {
    ref?: CheckBoxesRef;
  }
) => JSX.Element;

function VirtualTableRef<D extends object>(
  props: VirtualTableProps<D>,
  ref?: CheckBoxesRef
) {
  const { actions, refs, state } = useVirtualTable(props, ref);
  return (
    <Table.Root
      ref={refs.parentRef}
      fixed
      css={props.css}
      style={{
        "--virtualPaddingBottom": `${state.paddingBottom}px`,
        "--virtualPaddingTop": `${state.paddingTop}px`,
      }}
      {...actions.getTableProps()}
    >
      {props.noHeader ? (
        // If no header, we display a colgroup to set our cells width
        state.headerGroups.map((headerGroup, idx) => {
          return (
            <colgroup key={`list-header-${idx}`}>
              {headerGroup.headers.map((column) => {
                const hasWidth =
                  column.width && typeof column.width === "string";
                return (
                  <col
                    key={column.id}
                    style={{
                      ...(hasWidth && {
                        width: column.width,
                      }),
                      ...(column.basis &&
                        !hasWidth && {
                          width: `${
                            (column.basis / headerGroup.headers.length) * 100
                          }%`,
                        }),
                    }}
                  />
                );
              })}
            </colgroup>
          );
        })
      ) : (
        <Table.Head
          css={{
            position: "sticky",
            top: "0",
            zIndex: "1",
          }}
        >
          {state.headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const hasWidth =
                  column.width && typeof column.width === "string";
                return (
                  <Table.Cell
                    css={{
                      paddingLeft: props.canSelectItems ? "0" : "1.5rem",
                      ...(column.id === "selection" && {
                        padding: 0,
                      }),
                      ...(column.canSort && {
                        "&:hover": {
                          backgroundColor: "$gray100",
                        },
                      }),
                      ...(hasWidth && {
                        width: column.width,
                      }),
                      ...(column.basis &&
                        !hasWidth && {
                          width: `${
                            (column.basis / headerGroup.headers.length) * 100
                          }%`,
                        }),
                    }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {!column.disableSortBy && column.isSorted ? (
                      <Icon
                        css={{
                          display: "inline-block",
                          marginLeft: "1ch",
                          verticalAlign: "bottom",
                        }}
                        label={column.isSortedDesc ? "sort desc" : "sort asc"}
                        size="xsmall"
                      >
                        {column.isSortedDesc ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        )}
                        {props.setSortedDirection
                          ? props.setSortedDirection(!!column.isSortedDesc)
                          : null}
                      </Icon>
                    ) : null}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Head>
      )}
      <Table.Body
        css={{
          "&::after": {
            content: '""',
            display: "block",
            paddingBottom: "var(--virtualPaddingBottom)",
          },
          "&::before": {
            content: '""',
            display: "block",
            paddingTop: "var(--virtualPaddingTop)",
          },
        }}
        {...actions.getTableBodyProps()}
      >
        {state.rowVirtualizer.virtualItems.map((virtualRow) => {
          const row = state.rows[virtualRow.index];
          actions.prepareRow(row);
          // If our rowProps depends on the row data
          // retrieve it from a function, if not, just apply the rowProps to all the rows
          const rowProps =
            props.rowProps instanceof Function
              ? props.rowProps(row.original)
              : props.rowProps;
          const returnedRow = (
            <Table.Row
              {...row.getRowProps()}
              css={
                {
                  ...(row.isSelected && {
                    backgroundColor: "$gray50",
                  }),
                  ...rowProps?.css,
                } as CSS
              }
              clickable={Boolean(rowProps?.onClick)}
              onClick={() => {
                rowProps?.onClick?.(row.original);
              }}
              key={virtualRow.index}
              ref={virtualRow.measureRef}
            >
              {row.cells.map((cell) => {
                return (
                  <Table.Cell
                    css={{
                      paddingLeft: props.canSelectItems ? "0" : "1.5rem",
                      ...(cell.column.id === "selection" && {
                        padding: 0,
                      }),
                      ...(cell.column.id === "name" &&
                        state.breakpoint === "mobile" && {
                          paddingRight: "1rem",
                        }),
                    }}
                    {...cell.getCellProps()}
                    align={cell.column.align}
                  >
                    {cell.render("Cell")}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );

          return props.rowWrapperComponent ? (
            <props.rowWrapperComponent
              data={row.original}
              key={virtualRow.index}
            >
              {returnedRow}
            </props.rowWrapperComponent>
          ) : (
            returnedRow
          );
        })}
      </Table.Body>
    </Table.Root>
  );
}

export type { CheckBoxesRef, VirtualTableProps };
export { VirtualTable };
